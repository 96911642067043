import React, {useState} from 'react'
import authorizePostRequestWOT from '../api/authorizePostRequestWOT'
import FeedbackModal from './FeedbackModal'

const Footer = () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState({error: false, message: ''})

  const signUpNewsletter = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMsg({error: false, message: ''})
    const dt = {
      email
    }
    await authorizePostRequestWOT('newsletter', dt)
    .then(response => {
      console.log('newsletter', response.message);
      setMsg({error: false, message: response.message})
    })
    .catch((error) => {
      console.log('error newsletter', error.message)
      setMsg({error: true, message: error.message || 'Something went wrong'})
    });
    setLoading(false);
  }
  return (
    <footer className="site-footer">
      <div className="site-footer-top">
        <div className="container">
          <div className="row">

            <div className="col-lg-6 col-12">
              <h2 className="text-white mb-lg-0">Tickets2Go</h2>
            </div>

            <div className="col-lg-6 col-12 d-flex justify-content-lg-end align-items-center">
              <ul className="social-icon d-flex justify-content-lg-end">
                <li className="social-icon-item">
                  <a href="https://twitter.com/tickets2go_net" className="social-icon-link">
                    <span className="bi-twitter"></span>
                  </a>
                </li>

                <li className="social-icon-item">
                  <a href="https://www.instagram.com/tickets2go_net/" className="social-icon-link">
                    <span className="bi-instagram"></span>
                  </a>
                </li>

                <li className="social-icon-item">
                  <a href="https://wa.me/message/ADVONKHQ4ASGD1" className="social-icon-link">
                    <span className="bi-whatsapp"></span>
                  </a>
                </li>

                <li className="social-icon-item">
                  <a href="mailto:info@tickets2go.net" className="social-icon-link">
                    <span className="bi-google"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">

          <div className="col-lg-6 col-12 mb-4 pb-2">
            <h5 className="site-footer-title mb-3">Links</h5>

            <ul className="site-footer-links">
              <li className="site-footer-link-item">
                <a href="/about" className="site-footer-link">About us</a>
              </li>

              <li className="site-footer-link-item">
                <a href="/" className="site-footer-link">Events</a>
              </li>

              <li className="site-footer-link-item">
                <a href="https://vendor.tickets2go.net" className="site-footer-link">Sell Tickets</a>
              </li>

              <li className="site-footer-link-item">
                <a href="/feedback" className="site-footer-link">Contact us</a>
              </li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
            <h5 className="site-footer-title mb-3">Have a question?</h5>

            <p className="text-white d-flex mb-1">
              <a href="tel: +256770452110" className="site-footer-link">
                +25677-045-2110
              </a>
            </p>

            <p className="text-white d-flex">
              <a href="mailto:info@tickets2go.net" className="site-footer-link">
                info@tickets2go.net
              </a>
            </p>
          </div>

          <div className="col-lg-3 col-md-6 col-11 mb-4 mb-lg-0 mb-md-0">
            <h5 className="site-footer-title mb-3">Newsletter</h5>
            <p className="text-white d-flex mt-3 mb-2">Get buzzing updates. PS, no spamming</p>
            <form method="post" onSubmit={signUpNewsletter}>
              <p className={msg.error ? "text-danger" : "text-success"}>{msg.message}</p>
              <input type="email" value={email} onChange={(e)=> setEmail(e.target.value)} required className='form-control' placeholder='email' />
              {loading ? (
                <div className="loader m-5 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
              <button type='submit' className="btn custom-btn d-lg-block mt-2">Sign up</button>
              )}
            </form>
          </div>
        </div>
      </div>

      <div className="site-footer-bottom">
        <div className="container">
          <div className="row">

            <div className="col-lg-3 col-12 mt-5">
              <p className="copyright-text">{`Copyright © ${new Date().getFullYear()} Tickets2Go`}</p>
            </div>

            <div className="col-lg-8 col-12 mt-lg-5">
              <ul className="site-footer-links">
                <li className="site-footer-link-item">
                  <a href="/terms" className="site-footer-link">Terms &amp; Conditions</a>
                </li>

                <li className="site-footer-link-item">
                  <a href="/privacy" className="site-footer-link">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FeedbackModal />
    </footer>
  )
}

export default Footer
