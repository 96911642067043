import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet";
import { DebounceInput } from 'react-debounce-input';
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { format } from 'date-fns'
import { auth, provider, analytics } from '../firebaseConfig';
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import authorizeGetRequestWOT from '../api/authorizeGetRequestWOT';
import RightSideDrawer from '../components/RightSideDrawer';
import authorizePostRequestWOT from '../api/authorizePostRequestWOT';

const EventDetails = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [msg, setmsg] = useState('')
  const [data, setData] = useState([])
  const [currentCountry, setCurrentCountry] = useState('Uganda')
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [drawerContent, setDrawerContent] = useState({
    eventName: '',
    eventCode: '',
    description: '',
    posters: `{img1: ''}`,
    adPoster: 'https://api.tickets2go.net/avatars/tickets2Go.png'
  })
  const [noOfTickets, setNoOfTickets] = useState(1)
  const [selectedEventType, setSelectedEventType] = useState('')
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [amount, setAmount] = useState(0)
  const [errMsg, setErrMsg] = useState('')
  const [usernames, setUsernames] = useState('')
  const [email, setEmail] = useState('')
  let counter = 0;
  const [eventSEODetails, setEventSEODetails] = useState({
    eventName: '',
    code: '',
  })

  useEffect(() => {
    const data = async () => {
      setSelectedEventType('')
      if (counter === 0) {
        setLoading(true)
        if (localStorage.tickets2GoToken) {
          const usrdt = JSON.parse(localStorage.getItem('tickets2GoUser'));
          setEmail(usrdt.email)
          setUsernames(`${usrdt.firstName} ${usrdt.lastName}`)
        }
        let currentURL = window.location.href;
        currentURL = currentURL.split(`${process.env.REACT_APP_URL}/event-details`)
        currentURL = currentURL[1].split("?code=")
        console.log('currentURL', currentURL)
        currentURL = currentURL[1].split("&&")
        console.log('currentURL', currentURL)
        await authorizeGetRequestWOT(`event/eventCode/${currentURL[0]}`)
          .then(response => {
            console.log('got event', response.event);
            if (response.event) {
              if (response.event !== 0) {
                setDrawerContent(response.event)
                setNoOfTickets(1)
                setSelectedEventType('')
                setmsg('')
              } else {
                setmsg("Event Unavailable")
              }
            } else {
              setmsg("Event Unavailable")
            }
            axios.get('https://ipapi.co/json/').then((response) => {
              const cdata = response.data.country_name || 'Uganda';
              setCurrentCountry(cdata)
              authorizeGetRequestWOT(`event/country/${cdata}/0`)
                .then(response => {
                  // console.log('country events', response.events);
                  setData(response.events)
                })
                .catch((error) => {
                  // console.log('error logging in', error)
                  setData([])
                })
            }).catch((error) => {
              authorizeGetRequestWOT(`event/country/Uganda/0`)
                .then(response => {
                  // console.log('country events', response.events);
                  setData(response.events)
                })
                .catch((error) => {
                  // console.log('error logging in', error)
                  setData([])
                })
            });
          })
          .catch((error) => {
            console.log('error PaymentConfirmation', error)
            setmsg(error.message)
          })
        setLoading(false)
      }
    }
    data()
  }, [counter])

  const toggleDrawer = () => {
    return navigate("/");
  }
  const handleBackdropClick = () => {
    if (sidebarOpen) {
      return navigate("/");
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      toggleDrawer();
    }
  };
  const buyTickets = async (e) => {
    e.preventDefault();
    setLoading(true);
    if(!selectedEventType){
      setErrMsg("Please select a ticket type");
      setLoading(false);
      return
    }
    const dt = {
      amount: amount,
      currency: drawerContent.currency,
      customerEmail: email,
      customerName: usernames,
      eventId: drawerContent.id,
      ticketsNumber: noOfTickets,
      eventName: drawerContent.eventName,
    }
    // console.log('dt', dt)
    await authorizePostRequestWOT(`payments/getPaymentUrl`, dt)
      .then(response => {
        console.log('getPaymentUrl', response.url);
        if (response.url !== 0) {
          window.location.href = response.url
        } else {
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log('error getPaymentUrl', error)
        setLoading(false);
      });
    setSelectedEventType('')
    logEvent(analytics, `pay_${dt.eventId}`);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{drawerContent.eventName || 'Events - Tickets2Go'}</title>
        <link rel="canonical" href={`https://tickets2go.net/event-details?code=${drawerContent.eventCode}&&${drawerContent.eventName.toString().toLowerCase().replace(/\s/g, '')}`} />
        <meta property="og:title" content="Tickets2Go" />
        <meta property="og:description" content={drawerContent.description} />
        <meta property="og:image" content={drawerContent.adPoster} />
        <meta property="og:url" content={`https://tickets2go.net/event-details?code=${drawerContent.eventCode}&&${drawerContent.eventName.toString().toLowerCase().replace(/\s/g, '')}`} />
        <meta name="keywords" content="concert tickets tickets2go tickets 2 go quicket eventbrite party events" />
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;700&display=swap" rel="stylesheet" />
      </Helmet>
      <main onKeyDown={handleKeyPress}>
        <Navbar reloadNav={1} />
        <RightSideDrawer isOpen={sidebarOpen} onClose={toggleDrawer} handleBackdropClick={handleBackdropClick}>

          {loading ? (
            <div className="loader m-5 text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {msg ? (
                <>
                  <h2>Message</h2>
                  <p>{msg}</p>
                </>
              ) : (
                <>
                  <h2>Reserve a spot</h2>
                  <p>TO : {drawerContent.eventName}</p>
                  <form method='post' onSubmit={buyTickets}>
                    {errMsg && (<p className='text-danger'>{errMsg}</p>)}
                    <div className="mb-3">
                      <label htmlFor="floatingInput1" className="form-label">Ticket Type <span className='text-danger'>*</span></label>
                      <select required id="floatingInput1" value={selectedEventType} onChange={(e) => {
                        setSelectedEventType(e.target.value)
                        JSON.parse(drawerContent.ticketTypes).forEach(element => {
                          if (Number(element.id) === Number(e.target.value)) {
                            setAmount(Number(element.price) * Number(noOfTickets))
                            setErrMsg('')
                          }
                        });
                      }} className="form-select mb-3" style={{ backgroundColor: "#E5E5E5" }} aria-label="event type" required>
                        <option>Select</option>
                        {JSON.parse(drawerContent.ticketTypes).map((item, index) => (
                          <option key={index} value={item.id}>{`${item.type} (${item.price} ${drawerContent.currency})`}</option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="floatingInput2" className="form-label">Number of Tickets <span className='text-danger'>*</span></label>
                      <input type="number" id="floatingInput2" value={noOfTickets} onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        if (regex.test(e.target.value)) {
                          setNoOfTickets(e.target.value)
                          if (Number(e.target.value) > 0) {
                            if (selectedEventType !== "") {
                              JSON.parse(drawerContent.ticketTypes).forEach(element => {
                                if (Number(element.id) === Number(selectedEventType)) {
                                  setAmount(Number(element.price) * Number(e.target.value))
                                  setErrMsg('')
                                }
                              });
                            } else {
                              console.log("valid 1")
                              setErrMsg("Please select a ticket type")
                            }
                          }
                        } else {
                          setNoOfTickets(1)
                          if (selectedEventType !== "") {
                            JSON.parse(drawerContent.ticketTypes).forEach(element => {
                              if (Number(element.id) === Number(selectedEventType)) {
                                setAmount(Number(element.price) * Number(e.target.value))
                                setErrMsg('')
                              }
                            });
                          } else {
                            console.log("valid 1")
                            setErrMsg("Please select a ticket type")
                          }
                          setErrMsg("Please enter valid number")
                        }
                      }} required className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="floatingInput4" className="form-label">Email (to recieve tickets) <span className='text-danger'>*</span></label>
                      <input type="email" id="floatingInput4" value={email} onChange={(e) => setEmail(e.target.value)} required className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="floatingInput3" className="form-label">Full Names <span className='text-danger'>*</span></label>
                      <input type="text" id="floatingInput3" value={usernames} onChange={(e) => setUsernames(e.target.value)} required className="form-control" />
                    </div>

                    <div className='mb-3'>
                      <button type='submit' className="btn custom-btn d-lg-block">{`Buy Ticket(s) @ ${drawerContent.currency} ${amount}`}</button>
                    </div>
                  </form>
                  <h5>Event Details</h5>
                  <div className='row'>
                    {JSON.parse(drawerContent.posters).img1 && (
                      <div className='col-sm-12'>
                        <img alt="img" src={JSON.parse(drawerContent.posters).img1} className="mt-2" style={{ objectFit: 'contain' }} width={300} height={150} />
                      </div>
                    )}
                    {JSON.parse(drawerContent.posters).img2 && (
                      <div className='col-sm-12'>
                        <img alt="img" src={JSON.parse(drawerContent.posters).img2} className="mt-2" style={{ objectFit: 'contain' }} width="100%" height={150} />
                      </div>
                    )}
                    {JSON.parse(drawerContent.posters).img3 && (
                      <div className='col-lg-12'>
                        <img alt="img" src={JSON.parse(drawerContent.posters).img3} className="mt-2" style={{ objectFit: 'contain' }} width="100%" height={150} />
                      </div>
                    )}
                  </div>
                  <p className="h4 mt-4">Event Name</p>
                  <p>{drawerContent.eventName}</p>
                  <p className="h4 mt-4">Event Code(share on socials)</p>
                  <p>{drawerContent.eventCode}</p>
                  <p className="h4 mt-4">Event URL(share on socials)</p>
                  <p>{`https://tickets2go.net/event-details?code=${drawerContent.eventCode}&&${drawerContent.eventName.toString().toLowerCase().replace(/\s/g, '')}`}</p>
                  <p className="h4">Event Description</p>
                  <p>{drawerContent.description}</p>
                  <p className="h4">Event Type</p>
                  <p>{drawerContent.eventType === 1 ? 'Physical Event' : drawerContent.eventType === 2 ? 'Online Event' : 'Hybrid Event'}</p>
                  {drawerContent.eventType === 1 && (
                    <>
                      <p className="h4">Event Location</p>
                      <p>{drawerContent.eventLocation}</p>
                    </>
                  )}
                  {drawerContent.eventType === 2 && (
                    <>
                      <p className="h4">Links to online Event</p>
                      {JSON.parse(drawerContent.eventLocation).map((item, index) => (
                        <p key={index}>{item.url}</p>
                      ))}
                    </>
                  )}
                  {drawerContent.eventType === 3 && (
                    <>
                      <p className="h4">Event Location</p>
                      <p>{JSON.parse(drawerContent.eventLocation).physicalLocation}</p>
                      <p className="h4">Links to online Event</p>
                      {JSON.parse(drawerContent.eventLocation).urls.map((item, index) => (
                        <p key={index}>{item.url}</p>
                      ))}
                    </>
                  )}
                  <p className="h4">Event Duration</p>
                  <p>{drawerContent.eventDuration === 1 ? 'Same day event' : 'Multiple day event'}</p>
                  {drawerContent.eventDuration === 1 ? (
                    <>
                      <p className="h4">Event Date</p>
                      <p>{format(new Date(JSON.parse(drawerContent.eventDate).date), 'PPPP')}</p>
                      <p className="h4">Event Start Time</p>
                        <p>{`${format(new Date(`${JSON.parse(drawerContent.eventDate).date} ${JSON.parse(drawerContent.eventDate).startTime}`), 'hh:mm')} ${format(new Date(`${JSON.parse(drawerContent.eventDate).date} ${JSON.parse(drawerContent.eventDate).startTime}`), 'aaa')}`}</p>
                        <p className="h4">Event End Time</p>
                        <p>{`${format(new Date(`${JSON.parse(drawerContent.eventDate).date} ${JSON.parse(drawerContent.eventDate).endTime}`), 'hh:mm')} ${format(new Date(`${JSON.parse(drawerContent.eventDate).date} ${JSON.parse(drawerContent.eventDate).endTime}`), 'aaa')}`}</p>
                    </>
                  ) : (
                    <>
                      {JSON.parse(drawerContent.eventDate).map((item, index) => {
                        const indd = index + 1;
                        return (
                          <>
                            <p key={index} className="h4">{`Event ${indd} Date`}</p>
                              <p>{item.date}</p>
                              <p>{format(new Date(item.date), 'PPPP')}</p>
                              <p className="h4">{`Event ${indd} Start Time`}</p>
                              <p>{format(new Date(`${item.date}T${item.startTime}:00.000Z`), 'pppp')}</p>
                              <p className="h4">{`Event ${indd} End Time`}</p>
                              <p>{format(new Date(`${item.date}T${item.endTime}:00.000Z`), 'pppp')}</p>
                          </>
                        )
                      })}
                    </>
                  )}
                  <p className="h4">Tickets</p>
                  {JSON.parse(drawerContent.ticketTypes).map((item, index) => (
                    <p key={index}>{`Ticket Type : ${item.type}  ,  Price : ${item.price}`}</p>
                  ))}
                  <p className="h4">Currency</p>
                  <p>{drawerContent.currency}</p>
                </>
              )}
            </>
          )}
        </RightSideDrawer>
        <section className="ticket-section section-padding" id="section_5">
          <div className="section-overlay"></div>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div class="col-lg-6 col-12">
                <form className="custom-form ticket-form mb-3 mb-lg-0" action="#" method="post">
                  <div class="ticket-form-body">
                    <DebounceInput
                      minLength={3}
                      debounceTimeout={300}
                      className="form-control"
                      placeholder=" 🔍 Search event name / code"
                      required
                      onChange={event => {
                        setLoading(true)
                        setLoading(false)
                      }} />
                    <span onClick={() => {
                      setSidebarOpen(true)
                    }}>
                      <i className='fa fa-sliders-h fa-lg'></i>
                      <span className='px-2'>Filter search</span>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section class="pricing-section pt-5 pb-5 section-bg" id="section_5">
          <div class="container">
            <div class="row">
              {loading ? (
                <div className="loader m-5 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-lg-8 col-12 mx-auto">
                    <span style={{ cursor: 'pointer' }} className="d-flex align-items-center justify-content-center" onClick={() => {
                      setSidebarOpen(true)
                    }}>
                      <h2 className="text-center">{`Events in ${currentCountry}`}</h2>
                      <i style={{ marginLeft: 10 }} className="fa fa-chevron-down fa-lg"></i>
                    </span>
                  </div>

                  {data.length >= 1 ? (
                    <>
                      {data.map((item, index) => {
                        let evntType, poster = JSON.parse(item.posters);
                        if (item.eventType === 1) {
                          evntType = 'Physical event'
                        } else if (item.eventType === 2) {
                          evntType = 'Online event'
                        } else if (item.eventType === 3) {
                          evntType = 'Hybrid event'
                        }
                        if (poster.img1) {
                          poster = poster.img1;
                        } else if (poster.img2) {
                          poster = poster.img2;
                        } else if (poster.img3) {
                          poster = poster.img3;
                        }
                        return (
                          <div key={index} className="col-lg-6 col-12 mt-4 mt-lg-3">
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                              setSidebarOpen(true)
                              console.log('drawerContent', item)
                            }} className="pricing-thumb">
                              <div className="d-flex">
                                <div>
                                  <p>{item.eventName}</p>
                                  <small>{evntType}</small>
                                </div>

                                <img src={poster} className="img-fluid rounded-circle pricing-tag ms-auto" alt="img" style={{ objectFit: 'cover' }} />

                              </div>

                              <p className="mt-3" style={{ minHeight: 80 }}>{item.description}</p>

                              <a className="link-fx-1 color-contrast-higher mt-4" href="#">
                                <span>Check Tickets</span>
                                <svg className="icon" viewBox="0 0 32 32" aria-hidden="true"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"><circle cx="16" cy="16" r="15.5"></circle><line x1="10" y1="18" x2="16" y2="12"></line><line x1="16" y1="12" x2="22" y2="18"></line></g></svg>
                              </a>
                              {/* <a className="link-fx-1 color-contrast-lower mt-4 ms-5" href="ticket.html">
                                <span>More Info</span>
                                <svg className="icon" viewBox="0 0 32 32" aria-hidden="true"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"><circle cx="16" cy="16" r="15.5"></circle><line x1="10" y1="18" x2="16" y2="12"></line><line x1="16" y1="12" x2="22" y2="18"></line></g></svg>
                              </a> */}
                            </div>
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <p className="text-danger mt-3 text-center">No events retrieved</p>
                  )}
                </>
              )}

            </div>
          </div>
        </section>

      </main>

      <Footer />
    </>
  )
}

export default EventDetails
