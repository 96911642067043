import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet";
import { DebounceInput } from 'react-debounce-input';
import { format } from 'date-fns'
import PasswordMask from 'react-password-mask';
import { logEvent } from "firebase/analytics";
import { loadGapiInsideDOM } from "gapi-script";
import { onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, signOut, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth, provider, analytics } from '../firebaseConfig';
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import authorizeGetRequestWOT from '../api/authorizeGetRequestWOT';
import RightSideDrawer from '../components/RightSideDrawer';
import authorizePostRequestWOT from '../api/authorizePostRequestWOT';
import FeedbackModal from '../components/FeedbackModal';
// import MarqueeTitle from '../components/MarqueeTitle'

const gapi = window.gapi
const NearByEvents = () => {
  const [loading, setLoading] = useState(true);
  const [buyLoading, setBuyLoading] = useState(false);
  const [data, setData] = useState([])
  const [currentCountry, setCurrentCountry] = useState('Uganda')
  const [searchTxt, setSearchTxt] = useState('')
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCountryId, setSelectedCountryId] = useState('')
  const [selectedEventType, setSelectedEventType] = useState('')
  const [towns, setTowns] = useState([])
  const [townLoading, setTownLoading] = useState(false)
  const [selectedTown, setSelectedTown] = useState('')
  const [drawerContentType, setDrawerContentType] = useState('1')
  const [drawerContent, setDrawerContent] = useState('')
  const [noOfTickets, setNoOfTickets] = useState(1)
  const [usernames, setUsernames] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [amount, setAmount] = useState(0)
  const [errMsg, setErrMsg] = useState('')
  const [isSigningIn, setisSigningIn] = useState('sign')
  const [loginMsg, setLoginMsg] = useState({
    err: false,
    msg: '',
  })
  const [userData, setUserData] = useState({
    email: "",
    firstName: "",
    lastName: "",
  })
  const [confirmTxt, setconfirmTxt] = useState('')
  const [reloadNav, setReloadNav] = useState(1)
  const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
  const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

  const textStyle = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 1,
  }
  useEffect(() => {
    const data = async () => {
      setLoading(true)
      setSelectedEventType('')
      await loadGapiInsideDOM();
      if (localStorage.tickets2GoToken) {
        const usrdt = JSON.parse(localStorage.getItem('tickets2GoUser'));
        setUserData(usrdt);
        setEmail(usrdt.email)
        setUsernames(`${usrdt.firstName} ${usrdt.lastName}`)
        // console.log("usrdt", usrdt, usrdt.email)
      }
      const a = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });
      await a.get('https://ipapi.co/json/')
        .then((response) => {
          const cdata = response.data.country_name || 'Uganda';
          setCurrentCountry(cdata)
          authorizeGetRequestWOT(`event/country/${cdata}/0`)
            .then(response => {
              // console.log('country events', response.events);
              setData(response.events)
              authorizeGetRequestWOT(`event/countries`)
                .then(response => {
                  // console.log('countries', response.countries);
                  setCountries(response.countries)
                  setLoading(false)
                })
                .catch((error) => {
                  // console.log('error countries', error)
                  setCountries([])
                  setLoading(false)
                })
            })
            .catch((error) => {
              // console.log('error events for country', error)
              setData([])
              authorizeGetRequestWOT(`event/countries`)
                .then(response => {
                  // console.log('countries', response.countries);
                  setCountries(response.countries)
                  setLoading(false)
                })
                .catch((error) => {
                  // console.log('error countries', error)
                  setCountries([])
                  setLoading(false)
                })
            })
        }).catch((error) => {
          // console.log('inside failed ip country', error)
          authorizeGetRequestWOT(`event/country/Uganda/0`)
            .then(response => {
              // console.log('country events', response.events);
              setData(response.events)
              authorizeGetRequestWOT(`event/countries`)
                .then(response => {
                  // console.log('countries', response.countries);
                  setCountries(response.countries)
                  setLoading(false)
                })
                .catch((error) => {
                  // console.log('error countries', error)
                  setCountries([])
                  setLoading(false)
                })
            })
            .catch((error) => {
              // console.log('error logging in', error)
              setData([])
              authorizeGetRequestWOT(`event/countries`)
                .then(response => {
                  // console.log('countries', response.countries);
                  setCountries(response.countries)
                  setLoading(false)
                })
                .catch((error) => {
                  // console.log('error countries', error)
                  setCountries([])
                  setLoading(false)
                })
            })
        });

      const listen = onAuthStateChanged(auth, (user) => {
        if (!user) {
          logout()
        }
      });
    }
    data()
  }, [])

  const handleCalendar = async (dt) => {
    // console.log('data calendar', dt)
    await gapi.load('client:auth2', () => {
      gapi.client
        .init({
          apiKey: process.env.REACT_APP_calendarApiKey,
          clientId: process.env.REACT_APP_gClientId,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(() => {
          gapi.client.load('calendar', 'v3', () => console.log('calendar'))
          gapi.auth2.getAuthInstance().signIn()
            .then(() => {
              let loc = 'online event', estartDate, eendDate;
              if (dt.eventType === 1) {
                loc = dt.eventLocation
              } else if (dt.eventType === 2) {
                loc = 'online event'
              } else if (dt.eventType === 3) {
                loc = JSON.parse(dt.eventLocation).physicalLocation
              }
              if (dt.eventDuration === 1) {
                estartDate = `${JSON.parse(dt.eventDate).date}T${JSON.parse(dt.eventDate).startTime}:00-07:00`
                eendDate = `${JSON.parse(dt.eventDate).date}T${JSON.parse(dt.eventDate).endTime}:00-07:00`
              } else if (dt.eventDuration === 2) {
                const dtarr = JSON.parse(dt.eventDate)
                estartDate = `${dtarr[0].date}T${dtarr[0].startTime}:00-07:00`
                eendDate = `${dtarr[dtarr.length - 1].date}T${dtarr[dtarr.length - 1].endTime}:00-07:00`
              }
              const descri = `${dt.description} \n Purchase your tickets at https://tickets2go.net/event-details?code=${dt.eventCode}&&${dt.eventName.trim()}`
              const event = {
                'summary': dt.eventName,
                'location': loc,
                'description': descri,
                'start': {
                  'dateTime': estartDate,
                },
                'end': {
                  'dateTime': eendDate,
                },
                'attendees': [
                  { 'email': 'info@tickets2go.net' },
                ],
                'reminders': {
                  'useDefault': false,
                  'overrides': [
                    { 'method': 'email', 'minutes': 24 * 60 },
                    { 'method': 'popup', 'minutes': 30 },
                  ],
                },
              };
              const reqCalendar = gapi.client.calendar.events.insert({
                calendarId: 'primary',
                resource: event,
              })
              reqCalendar.execute((event) => {
                window.open(event.htmlLink)
              })
            })
            .catch((error) => {
              console.error('Error initializing Google API client:', error);
            });
        })
        .catch((error) => {
          console.log('Error initializing Google API client:', error);
        });
    });
  }
  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen)
  }
  const checkUser = () => {
    if (localStorage.tickets2GoToken) {
      setUserData(JSON.parse(localStorage.getItem('tickets2GoUser')))
    }
  }
  const handleBackdropClick = () => {
    if (sidebarOpen) {
      toggleDrawer();
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      toggleDrawer();
    }
  };
  const applyFilter = async () => {
    setLoading(true);
    toggleDrawer();
    let evtype;
    if (selectedEventType) {
      evtype = selectedEventType
    } else {
      evtype = 0
    }
    const dt = {
      lastItemId: 0,
      eventType: evtype,
      country: selectedCountry,
      town: selectedTown,
    }
    await authorizePostRequestWOT(`event/filter`, dt)
      .then(response => {
        console.log('event filter', response.events);
        if (response.events !== 0) {
          setData(response.events)
        } else {
          setData([])
        }
      })
      .catch((error) => {
        console.log('error event filter', error)
        setData([])
      });
    setLoading(false);
  }
  const buyTickets = async (e) => {
    e.preventDefault();
    setBuyLoading(true);
    setconfirmTxt('')
    setErrMsg('')
    console.log('selectedEventType', selectedEventType)
    if(!selectedEventType){
      setErrMsg("Please select a ticket type");
      setBuyLoading(false);
      return
    }
    const dt = {
      amount: amount,
      currency: drawerContent.currency,
      customerEmail: email,
      customerName: usernames,
      eventId: drawerContent.id,
      ticketsNumber: noOfTickets,
      eventName: drawerContent.eventName,
    }
    // console.log('dt', dt)
    await authorizePostRequestWOT(`payments/getPaymentUrl`, dt)
      .then(response => {
        // console.log('getPaymentUrl', response.url);
        if (response.url !== 0) {
          if (amount > 0) {
            window.location.href = response.url
          } else {
            setconfirmTxt(response.message)
          }
        }
        setBuyLoading(false);
      })
      .catch((error) => {
        // console.log('error getPaymentUrl', error)
        setErrMsg('Something went wrong, try again later')
        setBuyLoading(false);
      });
      setSelectedEventType('')
    logEvent(analytics, `pay_${dt.eventId}`);
  }
  const signIn = () => {
    if (!localStorage.tickets2GoToken) {
      setDrawerContentType('3')
      setSidebarOpen(true)
      setLoginMsg({
        err: false,
        msg: '',
      })
      setEmail('')
      setPassword('')
      setFirstName('')
      setLastName('')
      setisSigningIn('sign')
    }
  }
  const onSubmitSignIn = async (e) => {
    e.preventDefault()
    setBuyLoading(true)
    setLoginMsg({
      err: false,
      msg: '',
    })
    if (isSigningIn === 'sign') {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // const user = userCredential.user;

          const dt = {
            email,
          }
          authorizePostRequestWOT('user/login', dt)
            .then(response => {
              // console.log('login message', response);
              setLoginMsg({
                err: false,
                msg: 'success',
              })
              localStorage.setItem('tickets2GoToken', response.token);
              localStorage.setItem('tickets2GoUser', JSON.stringify(response.user));
              checkUser()
              setReloadNav(reloadNav + 1)
              setBuyLoading(false)
            })
            .catch((error) => {
              // console.log('error logging in', error)
              setLoginMsg({
                err: true,
                msg: error.message || 'something went wrong, try again',
              })
              setBuyLoading(false)
            });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const msg = {
            errorCode,
            errorMessage
          }
          // console.log('err sign in firebase', msg)
          setLoginMsg({
            err: true,
            msg: errorMessage,
          })
          setBuyLoading(false)
        });
    } else if (isSigningIn === 'create') {
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // const user = userCredential.user;
          const dt = {
            firstName,
            lastName,
            email,
            usrLevel: 'client',
            uid: userCredential.user.uid,
          }
          authorizePostRequestWOT('user/register', dt)
            .then(response => {
              // console.log('register message', response);
              setLoginMsg({
                err: false,
                msg: 'success',
              })
              localStorage.setItem('tickets2GoToken', response.token);
              localStorage.setItem('tickets2GoUser', JSON.stringify(response.user));
              checkUser()
              setReloadNav(reloadNav + 1)
              setBuyLoading(false)
            })
            .catch((error) => {
              // console.log('error logging in', error)
              setLoginMsg({
                err: true,
                msg: error.message || 'something went wrong, try again',
              })
              setBuyLoading(false)
            });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const msg = {
            errorCode,
            errorMessage
          }
          // console.log('err sign up firebase', msg)
          setLoginMsg({
            err: true,
            msg: errorMessage,
          })
          setBuyLoading(false)
        });
    } else if (isSigningIn === 'reset') {
      try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
        setLoginMsg({
          err: false,
          msg: 'Check your email for furthur instructions',
        })
        setEmail('')
        setBuyLoading(false)
      } catch (err) {
        // console.error(err);
        // alert(err.message);
        setLoginMsg({
          err: true,
          msg: err.message,
        })
        setBuyLoading(false)
      }
    }

  }
  const logout = () => {
    signOut(auth);
    localStorage.clear();
    setReloadNav(reloadNav + 1)
  };
  const signInWithGoogle = async () => {
    await signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        const nm = user.displayName.split(" ")
        // console.log('google result', nm, user)
        const dt = {
          firstName: nm[0],
          lastName: nm[1],
          email: user.email,
          usrLevel: 'client',
          uid: user.uid,
        }
        authorizePostRequestWOT('user/register', dt)
          .then(response => {
            // console.log('register message', response);
            setLoginMsg({
              err: false,
              msg: 'success',
            })
            localStorage.setItem('tickets2GoToken', response.token);
            localStorage.setItem('tickets2GoUser', JSON.stringify(response.user));
            checkUser()
            setReloadNav(reloadNav + 1)
            setBuyLoading(false)
          })
          .catch((error) => {
            // console.log('error logging in', error)
            setLoginMsg({
              err: true,
              msg: error.message || 'something went wrong, try again',
            })
            setBuyLoading(false)
          });
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        const dt = {
          errorCode,
          errorMessage,
          email,
          credential,
        }
        // console.log('google error', dt)
        setLoginMsg({
          err: true,
          msg: errorMessage || 'something went wrong, try again',
        })
        setBuyLoading(false)
      });
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Events - Tickets2Go</title>
        <link rel="canonical" href="https://tickets2go.net/" />
        <meta property="og:title" content="Tickets2Go" />
        <meta property="og:description"
          content="We make buying tickets to your favourite events very easy, comfortable and accept almost all payment methods. In a few steps, you will be ready to go" />
        <meta property="og:image"
          content="https://api.tickets2go.net/avatars/tickets2Go.png" />
        <meta property="og:url" content="https://tickets2go.net/" />
        <meta name="keywords" content="concert ticketstogo tickets tickets2go tickets 2 go quicket eventbrite party events" />
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;700&display=swap" rel="stylesheet" />

      </Helmet>
      <main onKeyDown={handleKeyPress}>
        <Navbar signIn={signIn} logOut={logout} reloadNav={reloadNav} />
        <RightSideDrawer isOpen={sidebarOpen} onClose={toggleDrawer} handleBackdropClick={handleBackdropClick}>
          {drawerContentType === '1' && (
            <>
              <h2>Filter Options</h2>
              <div className="mb-3">
                <label htmlFor="floatingInput1" className="form-label">Event Types</label>
                <select id="floatingInput1" value={selectedEventType} onChange={(e) => setSelectedEventType(e.target.value)} className="form-select mb-3" style={{ backgroundColor: "#E5E5E5" }} aria-label="event type" required>
                  <option>Select</option>
                  <option value="0">All Events</option>
                  <option value="1">Physical Event</option>
                  <option value="2">Online Event</option>
                  {/* remove hybrid events from options, in api add them to both phy & online event search */}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="floatingInput2" className="form-label">Country</label>
                <select id="floatingInput2" value={selectedCountryId} onChange={async (e) => {
                  setTownLoading(true)
                  setSelectedCountryId(e.target.value)
                  setSelectedTown('')
                  countries.forEach(element => {
                    if (Number(element.id) === Number(e.target.value)) {
                      setSelectedCountry(element.name)
                      setCurrentCountry(element.name)
                      return;
                    }
                  });
                  await authorizeGetRequestWOT(`event/towns/${e.target.value}`)
                    .then(response => {
                      // console.log('countries', response.countries);
                      setTowns(response.towns)
                    })
                    .catch((error) => {
                      // console.log('error logging in', error)
                      setTowns([])
                    })
                  setTownLoading(false)
                }} className="form-select mb-3" style={{ backgroundColor: "#E5E5E5" }} aria-label="event type" required>
                  <option>Select</option>
                  {countries.map((item, index) => (
                    <option key={index} value={item.id}>{`${item.name} (${item.noOfEvents})`}</option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                {townLoading ? (
                  <div id="spinner" className="show mx-4 mt-4 translate-middle d-flex align-items-center justify-content-center">
                    <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <label htmlFor="floatingInput3" className="form-label">Towns</label>
                    <select id="floatingInput3" value={selectedTown} onChange={async (e) => {
                      setSelectedTown(e.target.value)
                    }} className="form-select mb-3" style={{ backgroundColor: "#E5E5E5" }} aria-label="event type" required>
                      <option>Select</option>
                      {towns.length >= 1 && (
                        <>
                          {towns.map((item, index) => (
                            <option key={index} value={item.name}>{`${item.name} (${item.noOfEvents})`}</option>
                          ))}
                        </>
                      )}
                    </select>
                  </>

                )}
              </div>
              <div className='mb-3'>
                <button onClick={() => applyFilter()} className="btn custom-btn d-lg-block">Apply filter</button>
              </div>
            </>
          )}
          {drawerContentType === '2' && (
            <>
              {buyLoading ? (
                <div className="loader m-5 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <h2>Reserve a spot</h2>
                  <p>TO : {drawerContent.eventName}</p>
                  <form method='post' onSubmit={buyTickets}>
                    {errMsg && (
                      <div class="alert alert-danger" role="alert">
                        {errMsg}
                      </div>
                    )}
                    {confirmTxt && (
                      <div class="alert alert-success" role="alert">
                        {confirmTxt}
                      </div>
                    )}
                    <div className="mb-3">
                      <label htmlFor="floatingInput1" className="form-label">Ticket Type <span className='text-danger'>*</span></label>
                      <select required id="floatingInput1" value={selectedEventType} onChange={(e) => {
                        setSelectedEventType(e.target.value)
                        JSON.parse(drawerContent.ticketTypes).forEach(element => {
                          if (Number(element.id) === Number(e.target.value)) {
                            setAmount(Number(element.price) * Number(noOfTickets))
                            setErrMsg('')
                          }
                        });
                      }} className="form-select mb-3" style={{ backgroundColor: "#E5E5E5" }} aria-label="event type" required>
                        <option>Select</option>
                        {JSON.parse(drawerContent.ticketTypes).map((item, index) => (
                          <option key={index} value={item.id}>{`${item.type} (${item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${drawerContent.currency})`}</option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="floatingInput2" className="form-label">Number of Tickets <span className='text-danger'>*</span></label>
                      <input type="number" id="floatingInput2" value={noOfTickets} onChange={(e) => {
                        const regex = /^[0-9]*$/;
                        if (regex.test(e.target.value)) {
                          // console.log('passed reg test')
                          if(Number(e.target.value) !== 0){
                            setNoOfTickets(e.target.value)
                          } else {
                            setNoOfTickets("")
                            setAmount(0);
                            // console.log('inside 0')
                          }
                          if (Number(e.target.value) > 0) {
                            if (selectedEventType !== "") {
                              JSON.parse(drawerContent.ticketTypes).forEach(element => {
                                if (Number(element.id) === Number(selectedEventType)) {
                                  setAmount(Number(element.price) * Number(e.target.value))
                                  setErrMsg('')
                                }
                              });
                            } else {
                              // console.log("valid 1")
                              setErrMsg("Please select a ticket type")
                            }
                          }
                        } else {
                          // console.log('failed reg test')
                          setNoOfTickets(1)
                          if (selectedEventType !== "") {
                            JSON.parse(drawerContent.ticketTypes).forEach(element => {
                              if (Number(element.id) === Number(selectedEventType)) {
                                setAmount(Number(element.price) * Number(e.target.value))
                                setErrMsg('')
                              }
                            });
                          } else {
                            // console.log("valid 1")
                            setErrMsg("Please select a ticket type")
                          }
                          setErrMsg("Please enter valid number")
                        }
                      }} required className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="floatingInput4" className="form-label">Email (to recieve tickets) <span className='text-danger'>*</span></label>
                      <input type="email" id="floatingInput4" value={email} onChange={(e) => setEmail(e.target.value)} required className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="floatingInput3" className="form-label">Full Names <span className='text-danger'>*</span></label>
                      <input type="text" id="floatingInput3" value={usernames} onChange={(e) => setUsernames(e.target.value)} required className="form-control" />
                    </div>

                    <div className='mb-3'>
                      <button type='submit' className="btn custom-btn d-lg-block">{`Buy Ticket(s) @ ${drawerContent.currency} ${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</button>
                    </div>
                  </form>
                  {/* <button onClick={() => handleCalendar(drawerContent)} className="btn custom-btn d-lg-block">Add event to your calendar</button> */}
                  <h5>Event Details</h5>
                  {/* <img alt="img" src={JSON.parse(drawerContent.posters).img1} className="mt-2 img-fluid rounded-circle pricing-tag ms-auto" style={{ objectFit: 'contain' }} width="100%" height={150} /> */}
                  <div className='row'>
                    {JSON.parse(drawerContent.posters).img1 && (
                      <div className='col-sm-12'>
                        <img alt="img" src={JSON.parse(drawerContent.posters).img1} className="mt-2" style={{ objectFit: 'contain' }} width={300} height={150} />
                      </div>
                    )}
                    {JSON.parse(drawerContent.posters).img2 && (
                      <div className='col-sm-12'>
                        <img alt="img" src={JSON.parse(drawerContent.posters).img2} className="mt-2" style={{ objectFit: 'contain' }} width="100%" height={150} />
                      </div>
                    )}
                    {JSON.parse(drawerContent.posters).img3 && (
                      <div className='col-lg-12'>
                        <img alt="img" src={JSON.parse(drawerContent.posters).img3} className="mt-2" style={{ objectFit: 'contain' }} width="100%" height={150} />
                      </div>
                    )}
                  </div>
                  <p className="h4 mt-4">Event Name</p>
                  <p>{drawerContent.eventName}</p>
                  <p className="h4 mt-4">Event Code(share on socials)</p>
                  <p>{drawerContent.eventCode}</p>
                  <p className="h4 mt-4">Event URL(share on socials)</p>
                  <p>{`https://tickets2go.net/event-details?code=${drawerContent.eventCode}&&${drawerContent.eventName.toString().toLowerCase().replace(/\s/g, '')}`}</p>
                  <p className="h4">Event Description</p>
                  <p>{drawerContent.description}</p>
                  <p className="h4">Event Type</p>
                  <p>{drawerContent.eventType === 1 ? 'Physical Event' : drawerContent.eventType === 2 ? 'Online Event' : 'Hybrid Event'}</p>
                  {drawerContent.eventType === 1 && (
                    <>
                      <p className="h4">Event Location</p>
                      <p>{drawerContent.eventLocation}</p>
                    </>
                  )}
                  {drawerContent.eventType === 2 && (
                    <>
                      <p className="h4">Links to online Event</p>
                      {JSON.parse(drawerContent.eventLocation).map((item, index) => (
                        <p key={index}>{item.url}</p>
                      ))}
                    </>
                  )}
                  {drawerContent.eventType === 3 && (
                    <>
                      <p className="h4">Event Location</p>
                      <p>{JSON.parse(drawerContent.eventLocation).physicalLocation}</p>
                      <p className="h4">Links to online Event</p>
                      {JSON.parse(drawerContent.eventLocation).urls.map((item, index) => (
                        <p key={index}>{item.url}</p>
                      ))}
                    </>
                  )}
                  <p className="h4">Event Duration</p>
                  <p>{drawerContent.eventDuration === 1 ? 'Same day event' : 'Multiple day event'}</p>
                  {drawerContent.eventDuration === 1 ? (
                    <>
                      <p className="h4">Event Date</p>
                      <p>{format(new Date(JSON.parse(drawerContent.eventDate).date), 'PPPP')}</p>
                      <p className="h4">Event Start Time</p>
                        <p>{`${format(new Date(`${JSON.parse(drawerContent.eventDate).date} ${JSON.parse(drawerContent.eventDate).startTime}`), 'hh:mm')} ${format(new Date(`${JSON.parse(drawerContent.eventDate).date} ${JSON.parse(drawerContent.eventDate).startTime}`), 'aaa')}`}</p>
                        <p className="h4">Event End Time</p>
                        <p>{`${format(new Date(`${JSON.parse(drawerContent.eventDate).date} ${JSON.parse(drawerContent.eventDate).endTime}`), 'hh:mm')} ${format(new Date(`${JSON.parse(drawerContent.eventDate).date} ${JSON.parse(drawerContent.eventDate).endTime}`), 'aaa')}`}</p>
                    </>
                  ) : (
                    <>
                      {JSON.parse(drawerContent.eventDate).map((item, index) => {
                        const indd = index + 1;
                        return (
                          <>
                            <p key={index} className="h4">{`Event ${indd} Date`}</p>
                              <p>{item.date}</p>
                              <p>{format(new Date(item.date), 'PPPP')}</p>
                              <p className="h4">{`Event ${indd} Start Time`}</p>
                              <p>{format(new Date(`${item.date}T${item.startTime}:00.000Z`), 'pppp')}</p>
                              <p className="h4">{`Event ${indd} End Time`}</p>
                              <p>{format(new Date(`${item.date}T${item.endTime}:00.000Z`), 'pppp')}</p>
                          </>
                        )
                      })}
                    </>
                  )}
                  <p className="h4">Tickets</p>
                  {JSON.parse(drawerContent.ticketTypes).map((item, index) => (
                    <p key={index}>{`Ticket Type : ${item.type}  ,  Price : ${item.price}`}</p>
                  ))}
                  <p className="h4">Currency</p>
                  <p>{drawerContent.currency}</p>
                </>
              )}
            </>
          )}
          {drawerContentType === '3' && (
            <>
              {buyLoading ? (
                <div className="loader m-5 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  {!loginMsg.err && loginMsg.msg === 'success' ? (
                    <>
                      <p>You have successfully logged in.</p>
                      <p>Enjoy the ultimate party experience on us.</p>
                    </>
                  ) : (
                    <>
                      <h2>{isSigningIn === 'sign' ? 'Sign In' : isSigningIn === 'create' ? 'Create account' : 'Reset Password'}</h2>
                      <div class="col-lg-12 col-12 d-flex justify-content-center">
                        <div onClick={() => signInWithGoogle()} class="google-button">
                          <div class="icon">
                            <a href="" className="iconimg text-white" >
                              <span className="bi-google" style={{marginRight: 50}}></span>
                            </a>
                          </div>
                          <div class="textgg ml-2">
                            Continue with Google
                          </div>
                        </div>
                        </div>
                      <p className='mt-2 text-center text-primary'>--- Or Use your Email ---</p>
                      <p className={loginMsg.err ? 'text-danger' : 'text-primary'}>{loginMsg.msg}</p>
                      <form method='post' onSubmit={onSubmitSignIn}>
                        {isSigningIn === 'create' && (
                          <>
                            <div className='form-group mt-4 mb-2'>
                              <label htmlFor='subject'>First Name</label>
                              <input
                                type='firstName'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                                className='form-control form-control-lg'
                              />
                            </div>
                            <div className='form-group mt-2 mb-2'>
                              <label htmlFor='subject'>Last Name</label>
                              <input
                                type='lastName'
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                                className='form-control form-control-lg'
                              />
                            </div>
                          </>
                        )}
                        <div className='form-group mt-2 mb-2'>
                          <label htmlFor='subject'>Email</label>
                          <input
                            type='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className='form-control form-control-lg'
                          />
                        </div>
                        {isSigningIn === 'sign' || isSigningIn === 'create' ? (
                          <div className='form-group mt-2 mb-2'>
                            <label htmlFor='subject'>Password</label>
                            <PasswordMask
                              id="password"
                              name="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              inputClassName='form-control form-control-lg'
                              required
                            />
                          </div>
                        ) : (<></>)}
                        {isSigningIn === 'sign' && (
                          <p style={{ cursor: 'pointer' }} className='text-danger mt-1' onClick={() => {
                            setisSigningIn('reset')
                            setLoginMsg({
                              err: false,
                              msg: '',
                            })
                          }}>I forgot my password</p>
                        )}

                        <button type='submit' className="btn custom-btn d-lg-block mt-2">{isSigningIn === 'sign' ? 'Sign in' : isSigningIn === 'create' ? 'Create Account' : 'Reset Password'}</button>
                        <p style={{ cursor: 'pointer' }} className='text-primary mt-1' onClick={() => {
                          if (isSigningIn === 'sign') {
                            setisSigningIn('create')
                          } else {
                            setisSigningIn('sign')
                          }
                          setLoginMsg({
                            err: false,
                            msg: '',
                          })
                        }}>{isSigningIn === 'sign' ? "Don't have an account? Create one" : "Have an account? Sign in"}</p>
                      </form>
                    </>
                  )}

                </>
              )}
            </>
          )}

        </RightSideDrawer>
        <section className="ticket-section section-padding" id="section_5">
          <div className="section-overlay"></div>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div class="col-lg-6 col-12">
                <form className="custom-form ticket-form mb-3 mb-lg-0" method="post">
                  <div class="ticket-form-body">
                    <DebounceInput
                      minLength={3}
                      debounceTimeout={300}
                      className="form-control"
                      placeholder=" 🔍 Search event name / code"
                      required
                      onChange={event => {
                        setLoading(true)
                        const dt = {
                          eventType: selectedEventType,
                          country: selectedCountry,
                          town: selectedTown,
                          searchTxt: searchTxt,
                          lastItemId: 0,
                        }
                        setSearchTxt(event.target.value)
                        // console.log('event search', dt)
                        authorizeGetRequestWOT(`event/search/${event.target.value}/0`)
                          .then(response => {
                            if (response.events !== 0) {
                              setData(response.events)
                            } else {
                              setData([])
                            }
                          })
                          .catch((error) => {
                            // console.log('error logging in', error)
                            setData([])
                          })
                        setLoading(false)
                      }} />
                    <span onClick={() => {
                      setSidebarOpen(true)
                      setDrawerContentType('1')
                    }}>
                      <i className='fa fa-sliders-h fa-lg'></i>
                      <span className='px-2'>Filter search</span>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <FeedbackModal />
        <section class="pricing-section pt-5 pb-5 section-bg" id="section_5">
          <div class="container">
            <div class="row">
              {loading ? (
                <div className="loader m-5 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-lg-8 col-12 mx-auto">
                    <span style={{ cursor: 'pointer' }} className="d-flex align-items-center justify-content-center" onClick={() => {
                      setSidebarOpen(true)
                      setDrawerContentType('1')
                    }}>
                      <h2 className="text-center">{`Events in ${currentCountry}`}</h2>
                      <i style={{ marginLeft: 10 }} className="fa fa-chevron-down fa-lg"></i>
                    </span>
                  </div>
                  <div class="container">
                    <div class="row">
                      {data.length >= 1 ? (
                        <>
                          {data.map((item, index) => {
                            // console.log('item', item)
                            let evntType, poster = JSON.parse(item.posters);
                            if (item.eventType === 1) {
                              evntType = 'Physical event'
                            } else if (item.eventType === 2) {
                              evntType = 'Online event'
                            } else if (item.eventType === 3) {
                              evntType = 'Hybrid event'
                            }
                            if (poster.img1) {
                              poster = poster.img1;
                            } else if (poster.img2) {
                              poster = poster.img2;
                            } else if (poster.img3) {
                              poster = poster.img3;
                            }
                            return (
                              <>

                                <div key={index} class="col mb-4">
                                  <div
                                    onClick={() => {
                                      setSidebarOpen(true)
                                      setDrawerContent(item)
                                      setDrawerContentType('2')
                                      setNoOfTickets(1)
                                      setSelectedEventType('')
                                      // console.log('drawerContent', item)
                                    }} class="card mx-auto" style={{ width: '18rem', cursor: 'pointer', borderRadius: 25 }}>
                                    <img src={poster} class="postImg" alt="..." />
                                    <div class="card-body">
                                    <div className='d-flex align-items-center'>
                                        <i style={{ color: '#EE5007', marginRight: 5 }} className="fa fa-glass-cheers"></i>
                                      <p class="card-title" style={{fontWeight: 'bold', textTransform: 'capitalize', ...textStyle}}>{item.eventName}</p>
                                      </div>
                                      {item.eventType === 1 && (
                                        <div className='d-flex align-items-center'>
                                        <i style={{ color: '#EE5007', marginRight: 10 }} className="fa fa-map-marker-alt"></i>
                                        <p class="card-title" style={{fontSize: 14, ...textStyle}}>{item.eventLocation}</p>
                                        </div>
                                      )}
                                      {item.eventType === 2 && (
                                        <div className='d-flex align-items-center'>
                                        <i style={{ color: '#EE5007', marginRight: 10 }} className="fa fa-map-marker-alt"></i>
                                        <p class="card-title" style={{fontSize: 14, ...textStyle}}>Online Event</p>
                                        </div>
                                      )}
                                      {item.eventType === 3 && (
                                        <div className='d-flex align-items-center'>
                                        <i style={{ color: '#EE5007', marginRight: 10 }} className="fa fa-map-marker-alt"></i>
                                        <p class="card-title" style={{fontSize: 14, ...textStyle}}>{JSON.parse(item.eventLocation).physicalLocation}</p>
                                        </div>
                                      )}
                                      {item.eventDuration === 1 ? (
                                        <div className='d-flex align-items-center'>
                                          <i style={{ color: '#EE5007', marginRight: 10 }} className="fa fa-clock"></i>
                                        <p class="card-title" style={{fontSize: 14, ...textStyle}}>{format(new Date(JSON.parse(item.eventDate).date), 'PPPP')}</p>
                                        </div>
                                      ) : (
                                        <div className='d-flex align-items-center'>
                                          <i style={{ color: '#EE5007', marginRight: 10 }} className="fa fa-chevron-down"></i>
                                        <p class="card-title" style={{fontSize: 14, ...textStyle}}>{format(new Date(JSON.parse(item.eventDate)[0].date), 'PPPP')}</p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          })}

                        </>
                      ) : (
                        <p className="text-danger mt-3 text-center">No events retrieved</p>
                      )}
                    </div>
                  </div>
                </>
              )}

            </div>
          </div>
        </section>

      </main>

      <Footer />
    </>
  )
}

export default NearByEvents
