import React, { useState } from 'react'

const FeedbackModal = () => {
  const [loading, setLoading] = useState(true)
  const [msg, setMsg] = useState({ error: false, message: '' })
  const [fullNames, setFullNames] = useState('')
  const [email, setEmail] = useState('')
  const [feedback, setFeedback] = useState('')

  const onSubmit = async (e) => {
    e.preventDefault()
  }

  return (
    <div className="modal fade" id="feedbackModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Share your thoughts</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {loading ? (
            <>
              <div className="loader m-5 text-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <form method='post' onSubmit={onSubmit}>
              <div className="modal-body">
                {msg.error && (
                  <div className="alert alert-success text-primary">
                    <strong>{msg.message}</strong>
                  </div>
                )}
                <div className='col-lg-12 col-sm-11'>
                  <div className='form-group mt-4 mb-4'>
                    <label htmlFor='subject'>Title</label>
                    <input
                      name='formTitle'
                      value={fullNames}
                      onChange={(e) => setFullNames(e.target.value)}
                      required
                      className='form-control form-control-lg'
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                <button type="submit" className="btn btn-primary">Yes, proceed</button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default FeedbackModal
