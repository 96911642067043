import React, { useState } from 'react'
import { Helmet } from "react-helmet";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import authorizePostRequestWOT from '../api/authorizePostRequestWOT';

const Feedback = () => {
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState({ error: false, message: '' })
  const [fullNames, setFullNames] = useState('')
  const [email, setEmail] = useState('')
  const [feedback, setFeedback] = useState('')

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const dt = {
      email,
      fullNames,
      feedback,
    }
    await authorizePostRequestWOT('newsletter/feedback', dt)
    .then(response => {
      // console.log('feedback', response);
      setMsg({
        err: false,
        msg: response.message,
      })
      setFullNames('')
      setEmail('')
      setFeedback('')
      setLoading(false);
    })
    .catch((error) => {
      // console.log('error logging in', error)
      setMsg({
        err: true,
        msg: error.message || 'something went wrong, try again',
      })
      setLoading(false)
    });
    console.log('msg', msg);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact us - Tickets2Go</title>
        <link rel="canonical" href="https://tickets2go.net/feedback" />
        <meta property="og:title" content="Contact Tickets2Go" />
        <meta property="og:description"
          content="Get in touch with us. We make buying tickets to your favourite events very easy, comfortable and accept almost all payment methods. In a few steps, you will be ready to go" />
        <meta property="og:image"
          content="https://api.tickets2go.net/avatars/tickets2Go.png" />
        <meta property="og:url" content="https://tickets2go.net/feedback" />
        <meta name="keywords" content="concert tickets tickets2go tickets 2 go quicket eventbrite party events" />
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;700&display=swap" rel="stylesheet" />

      </Helmet>
      <main>
        <Navbar />
        {/* <section className="ticket-section section-padding" id="section_5">
          <div className="section-overlay"></div>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 col-12 text-center ">
                <h2 className='text-secondary'>Share your thoughts</h2>
              </div>
            </div>
          </div>
        </section> */}

        <section class="contact-section section-padding" id="section_6">
          <div class="container">
            <div class="row">

              <div class="col-lg-8 col-12 mx-auto">
                <h2 class="text-center mb-4">Reaching out? Let's talk</h2>

                <div class="tab-content shadow-lg mt-5" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="nav-ContactForm" role="tabpanel"
                    aria-labelledby="nav-ContactForm-tab">
                    {loading ? (
                      <>
                        <div className="loader m-5 text-center">
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <form class="custom-form contact-form mb-5 mb-lg-0" onSubmit={onSubmit} method="post"
                        role="form">
                        <div class="contact-form-body">
                          {msg.msg && (
                            <div className={msg.error ? "alert alert-danger text-primary" : "alert alert-success text-primary"}>
                              <strong>{msg.msg}</strong>
                            </div>
                          )}
                          <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                              <input type="text" value={fullNames}
                                onChange={(e) => setFullNames(e.target.value)} name="contact-name" id="contact-name"
                                class="form-control" placeholder="Full names" required />
                            </div>

                            <div class="col-lg-6 col-md-6 col-12">
                              <input type="email" value={email}
                                onChange={(e) => setEmail(e.target.value)} name="contact-email" id="contact-email"
                                class="form-control"
                                placeholder="Email address" required />
                            </div>
                          </div>

                          <textarea value={feedback}
                            onChange={(e) => setFeedback(e.target.value)} name="contact-message" rows="4" class="form-control"
                            id="contact-message" placeholder="Message" required></textarea>

                          <div class="col-lg-4 col-md-10 col-8 mx-auto">
                            <button type="submit" class="form-control">Send message</button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default Feedback
