import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import videos from '../assets/video/pexels-2022395.mp4';
import Footer from '../components/Footer';
import Navbar from "../components/Navbar";
import pexelsAlexander from '../assets/images/pexels-alexander-suhorucov-6457579.jpg'

const Home = () => {
  useEffect(() => {
    const data = async () => {
      // window.func();
    }
    // data()
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About us - Tickets2Go</title>
        <link rel="canonical" href="https://tickets2go.net/about" />
        <meta property="og:title" content="About Tickets2Go" />
        <meta property="og:description"
          content="We make buying tickets to your favourite events very easy, comfortable and accept almost all payment methods. In a few steps, you will be ready to go" />
        <meta property="og:image"
          content="https://api.tickets2go.net/avatars/tickets2Go.png" />
        <meta property="og:url" content="https://tickets2go.net/about" />
        <meta name="keywords" content="concert tickets ticketstogo tickets2go tickets 2 go quicket eventbrite party events" />
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;700&display=swap" rel="stylesheet" />

      </Helmet>
      <main>

        <Navbar reloadNav={1} />

        <section class="hero-section" id="section_1">
          <div class="section-overlay"></div>

          <div class="container d-flex justify-content-center align-items-center">
            <div class="row">
              <div class="col-12 mt-auto mb-5 text-center">
                <small>Tapowa Adventures Presents</small>

                <h1 class="text-white mb-5">Vibes Mu Kikade</h1>

                <Link class="btn custom-btn smoothscroll" to="/event-details?code=658283&&vibesmukikadde">Get tickets</Link>
              </div>

              <div class="col-lg-12 col-12 mt-auto d-flex flex-column flex-lg-row text-center">
                <div class="date-wrap">
                  <h5 class="text-white">
                    <i class="custom-icon bi-clock me-2"></i>
                    23<sup>rd</sup>, June 2024
                  </h5>
                </div>

                <div class="location-wrap mx-auto py-3 py-lg-0">
                  <h5 class="text-white">
                    <i class="custom-icon bi-geo-alt me-2"></i>
                    Jinja, Uganda
                  </h5>
                </div>

                <div class="social-share">
                  <ul class="social-icon d-flex align-items-center justify-content-center">
                    <span class="text-white me-3">Share:</span>

                    <li class="social-icon-item">
                      <a href="https://web.whatsapp.com/send?text=https://tickets2go.net/event-details?code=658283&&vibesmukikadde" target="_blank" rel="nofollow noopener" data-action="share/whatsapp/share" class="social-icon-link">
                        <span class="bi-whatsapp"></span>
                      </a>
                    </li>

                    <li class="social-icon-item">
                      <a href="https://www.facebook.com/sharer/sharer.php?u=https://tickets2go.net/event-details?code=658283&&vibesmukikadde" class="social-icon-link">
                        <span class="bi-facebook"></span>
                      </a>
                    </li>

                    <li class="social-icon-item">
                      <a href="https://twitter.com/intent/tweet?url=https://tickets2go.net/event-details?code=658283&&vibesmukikadde" class="social-icon-link">
                        <span class="bi-twitter"></span>
                      </a>
                    </li>

                    <li class="social-icon-item">
                      <a href="https://www.instagram.com/sharer/sharer.php?u=https://tickets2go.net/event-details?code=658283&&vibesmukikadde" class="social-icon-link">
                        <span class="bi-instagram"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="video-wrap">
            <video autoPlay={true} loop={true} muted={true} class="custom-video" poster="">
              <source src={videos} type="video/mp4" />

              Your browser does not support this video format.
            </video>
          </div>
        </section>

        <section class="about-section section-padding" id="section_2">
          <div class="container">
            <div class="row">

              <div class="col-lg-6 col-12 mb-4 mb-lg-0 d-flex align-items-center">
                <div class="services-info">
                  <h2 class="text-white mb-4">About Tickets2Go</h2>
                  <p class="text-white">We make buying tickets to your favourite events very easy, comfortable and accept almost all payment methods. Our goal is to elevate your event experience. Below is a list of our services :</p>
                  <p class="text-white"><b>Online Ticket Sales :</b> We offer online ticket sales through our website, tickets2go.net. Ticket sales for free events are provided free of charge. For paid events, a service fee of 5% of the total collected amount will be charged.</p>
                  <p class="text-white"><b>Onsite Ticket Sales:</b> Our team facilitates onsite ticket sales during events, ensuring a smooth process for attendees.</p>
                  <p class="text-white"><b>Gate Access Management:</b> We manage gate access to ensure the security and smooth flow of attendees during events.</p>
                  <p class="text-white"><b>Event Ushers:</b> Our trained ushers assist in guiding attendees, providing information, and ensuring a pleasant experience throughout the event.</p>
                  <p class="text-white"><b>Ticket Wristbands:</b> We offer ticket wristbands for efficient entry management and identification purposes.</p>
                  <p class="text-white"><b>Graphic Design Services:</b> We provide graphic design services for event posters and videos, ensuring visually appealing promotional materials.</p>
                  <p class="text-white"><b>Photography:</b> Our team captures memorable moments during events through professional photography services.</p>
                  <p class="text-white"><b>Event Marketing:</b> We employ productive social media marketing campaigns for your event.</p>
                  <h6 class="text-white mt-4">How it works!</h6>

                  <p class="text-white">To buy tickets, click the "View Event" button in the top menu to browse available events</p>
                  <p class="text-white">To sell tickets, Click the "Sell Tickets" button in the top menu, you will be redirected to a vendor login page</p>

                  <h6 class="text-white mt-4">Fees Structure</h6>

                  <p class="text-white">Our service is free of charge to Free Events</p>
                  <p class="text-white">Paid to Events will cost a 5% cut of the sales we make for you.No Extra Charges</p>
                  <p class="text-white">Contact us on <a href="https://wa.me/message/ADVONKHQ4ASGD1">whatsaap with this link</a> for a price quotation of our other services</p>
                </div>
              </div>

              <div class="col-lg-6 col-12">
                <div class="about-text-wrap">
                  <img src={pexelsAlexander} class="about-image img-fluid" />

                  <div class="about-text-info d-flex">
                    <div class="d-flex">
                      <i class="about-text-icon bi-person"></i>
                    </div>

                    <div class="ms-4">
                      <h3>Party Vibes only</h3>
                      <p class="mb-0">Have a hasle free event experience with us</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

      </main>

      <Footer />
    </>
  )
}

export default Home
