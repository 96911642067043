import React, { useEffect, useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from '../firebaseConfig';

const Navbar = ({signIn, logOut, reloadNav}) => {
  const [reload, setReload] = useState(0)
  const [userData, setUserData] = useState({
    email: "",
    firstName: "",
    lastName: "",
  })
  const [token, setToken] = useState('')

  useEffect(() => {
    const data = async () => {
      // console.log(reloadNav,reload)
      if (localStorage.tickets2GoToken) {
        setToken(localStorage.tickets2GoToken)
        setUserData(JSON.parse(localStorage.getItem('tickets2GoUser')))
      } else {
        setToken('')
      setUserData({
        email: "",
        firstName: "",
        lastName: "",
      })
      }
    }
    data()
  }, [reloadNav])

  const checkUser = () => {
    if (localStorage.tickets2GoToken) {
      setToken(localStorage.tickets2GoToken)
      setUserData(JSON.parse(localStorage.getItem('tickets2GoUser')))
    } else {
      setToken('')
      setUserData({
        email: "",
        firstName: "",
        lastName: "",
      })
    }
  }
  
  return (
    <>
      <header className="site-header">
        <div className="container">
          <div className="row">

            <div className="col-lg-12 col-12 d-flex flex-wrap">
              <p onClick={signIn} style={{cursor: token ? 'text' : 'pointer'}} className="d-flex me-4 mb-0">
                <i className="bi-person custom-icon me-2"></i>
                <strong className="text-dark">{!token ? 'Sign in' : `${userData.firstName} ${userData.lastName}`}</strong>
              </p>
              {token && (
                <p onClick={() => {
                  logOut()
                  checkUser()
                }} style={{cursor: 'pointer'}} className="me-4 mb-0">Log Out</p>
              )}
            </div>

          </div>
        </div>
      </header>

      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a href="/" className="navbar-brand">Tickets2Go</a>
          <div>
            <a href="/" className="btn custom-btn d-lg-none ms-auto me-4">View Events</a>

            {/* <a href="https://vendor.tickets2go.net" className="btn custom-btn d-lg-none ms-auto me-4 text-black" style={{ backgroundColor: '#F8CB2E' }}>Sell Tickets</a> */}
            <a href="https://vendor.tickets2go.net" onClick={()=> logEvent(analytics, `gotoSellTickets`)} className="btn custom-btn d-lg-none ms-auto me-4 text-black" style={{ backgroundColor: '#F8CB2E' }}>Sell Tickets</a>
          </div>
          {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> */}

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav align-items-lg-center ms-auto me-lg-5">
              {/* <li className="nav-item">
                <NavLink to="/" className="nav-link click-scroll">Home</NavLink>
              </li>

              <li className="nav-item">
                <NavLink to="/events" className="nav-link click-scroll">View Events</NavLink>
              </li> */}
            </ul>

            <a href="/" className="btn custom-btn d-lg-block d-none">View Events</a>

            {/* <a href="https://vendor.tickets2go.net" className="btn custom-btn d-lg-block d-none ms-2 text-black" style={{ backgroundColor: '#F8CB2E' }}>Sell Tickets</a> */}
            <a href="https://vendor.tickets2go.net" onClick={()=> logEvent(analytics, `gotoSellTickets`)} className="btn custom-btn d-lg-block d-none ms-2 text-black" style={{ backgroundColor: '#F8CB2E' }}>Sell Tickets</a>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
