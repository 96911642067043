import React from 'react'
import { useRouteError, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const Error = () => {
  const error = useRouteError();
  let navigate = useNavigate();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tickets2Go</title>
        <link rel="canonical" href="https://tickets2go.net/404" />
        <meta property="og:title" content="Error page Tickets2Go" />
        <meta property="og:description"
          content="Get in touch with us. We make buying tickets to your favourite events very easy, comfortable and accept almost all payment methods. In a few steps, you will be ready to go" />
        <meta property="og:image"
          content="https://api.tickets2go.net/avatars/tickets2Go.png" />
        <meta property="og:url" content="https://tickets2go.net/404" />
        <meta name="keywords" content="concert tickets tickets2go tickets 2 go quicket eventbrite party events" />
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;700&display=swap" rel="stylesheet" />

      </Helmet>
      <main>
        <Navbar />
        <section className="ticket-section section-padding" id="section_5">
          <div className="section-overlay"></div>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div class="col-lg-6 col-12">
                <form className="custom-form ticket-form mb-3 mb-lg-0" method="post">
                  <div class="ticket-form-body">
                  <h1 class="display-4 mb-4">{`${error.status} Error`}</h1>
                  <h1 class="mb-4">{error.statusText || error.message}</h1>
                  <p class="mb-4">{error.data}</p>
                  <button onClick={()=> navigate("/")} className="btn custom-btn d-lg-block mt-2">Back to home</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default Error
