import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import NearByEvents from './pages/NearByEvents';
import Ticket from './pages/Ticket';
import Error from './pages/Error';
import PaymentConfirmation from './pages/PaymentConfirmation';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsNConditions from './pages/TermsNConditions';
import Feedback from './pages/Feedback';
import EventDetails from './pages/EventDetails';

const App = () => {
  const [data, setdata] = useState([
    {
      path: "/",
      element: <NearByEvents />,
      errorElement: <Error />,
    },
    {
      path: "/event-details",
      element: <EventDetails />,
    },
    {
      path: "/about",
      element: <Home />,
    },
    {
      path: "/payment-confirmation",
      element: <PaymentConfirmation />,
    },
    {
      path: "/privacy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/terms",
      element: <TermsNConditions />,
    },
    {
      path: "/feedback",
      element: <Feedback />,
    },
  ])

  const router = createBrowserRouter(data);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
