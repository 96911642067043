import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from "react-helmet";
import { DebounceInput } from 'react-debounce-input';
import { useNavigate } from "react-router-dom";
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import authorizeGetRequestWOT from '../api/authorizeGetRequestWOT';
import RightSideDrawer from '../components/RightSideDrawer';

const PaymentConfirmation = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [msg, setmsg] = useState('')
  const [data, setData] = useState([])
  const [currentCountry, setCurrentCountry] = useState('Uganda')
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  let counter = 0;

  useEffect(() => {
    const data = async () => {
      console.log("counter", counter)
      // counter++
      // window.func();
      if(counter === 0){
        setLoading(true)
        let currentURL = window.location.href;
        currentURL = currentURL.split(`${process.env.REACT_APP_URL}/payment-confirmation`)
        await authorizeGetRequestWOT(`payments/webhook${currentURL[1]}`)
          .then(response => {
            console.log('success PaymentConfirmation', response.message);
            setmsg(response.message)
            axios.get('https://ipapi.co/json/').then((response) => {
              const cdata = response.data.country_name || 'Uganda';
              setCurrentCountry(cdata)
              authorizeGetRequestWOT(`event/country/${cdata}/0`)
                .then(response => {
                  // console.log('country events', response.events);
                  setData(response.events)
                })
                .catch((error) => {
                  // console.log('error logging in', error)
                  setData([])
                })
            }).catch((error) => {
              authorizeGetRequestWOT(`event/country/Uganda/0`)
                .then(response => {
                  // console.log('country events', response.events);
                  setData(response.events)
                })
                .catch((error) => {
                  // console.log('error logging in', error)
                  setData([])
                })
            });
            authorizeGetRequestWOT(`event/countries`)
              .then(response => {
                // console.log('countries', response.countries);
                setCountries(response.countries)
              })
              .catch((error) => {
                // console.log('error logging in', error)
                setCountries([])
              })
          })
          .catch((error) => {
            console.log('error PaymentConfirmation', error)
            setmsg(error.message)
          })
          setLoading(false)
          console.log("end counter")
      }
    }
    data()
  }, [counter])

  const toggleDrawer = () => {
    return navigate("/");
  }
  const handleBackdropClick = () => {
    if (sidebarOpen) {
      return navigate("/");
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      toggleDrawer();
    }
  };

  return (
    <>
    <main onKeyDown={handleKeyPress}>
        <Navbar reloadNav={1} />
        <RightSideDrawer isOpen={sidebarOpen} onClose={toggleDrawer} handleBackdropClick={handleBackdropClick}>
        <h2>Message</h2>
        {loading ? (
                <div className="loader m-5 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
        <p>{msg}</p>
              )}
        </RightSideDrawer>
        <section className="ticket-section section-padding" id="section_5">
          <div className="section-overlay"></div>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div class="col-lg-6 col-12">
                <form className="custom-form ticket-form mb-3 mb-lg-0" action="#" method="post">
                  <div class="ticket-form-body">
                    <DebounceInput
                      minLength={3}
                      debounceTimeout={300}
                      className="form-control"
                      placeholder=" 🔍 Search event name / code"
                      required
                      onChange={event => {
                        setLoading(true)
                        setLoading(false)
                      }} />
                    <span onClick={() => {
                      setSidebarOpen(true)
                    }}>
                      <i className='fa fa-sliders-h fa-lg'></i>
                      <span className='px-2'>Filter search</span>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section class="pricing-section pt-5 pb-5 section-bg" id="section_5">
          <div class="container">
            <div class="row">
              {loading ? (
                <div className="loader m-5 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-lg-8 col-12 mx-auto">
                    <span style={{ cursor: 'pointer' }} className="d-flex align-items-center justify-content-center" onClick={() => {
                      setSidebarOpen(true)
                    }}>
                      <h2 className="text-center">{`Events in ${currentCountry}`}</h2>
                      <i style={{ marginLeft: 10 }} className="fa fa-chevron-down fa-lg"></i>
                    </span>
                  </div>

                  {data.length >= 1 ? (
                    <>
                      {data.map((item, index) => {
                        let evntType, poster = JSON.parse(item.posters);
                        if (item.eventType === 1) {
                          evntType = 'Physical event'
                        } else if (item.eventType === 2) {
                          evntType = 'Online event'
                        } else if (item.eventType === 3) {
                          evntType = 'Hybrid event'
                        }
                        if (poster.img1) {
                          poster = poster.img1;
                        } else if (poster.img2) {
                          poster = poster.img2;
                        } else if (poster.img3) {
                          poster = poster.img3;
                        }
                        return (
                          <div key={index} className="col-lg-6 col-12 mt-4 mt-lg-3">
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                              setSidebarOpen(true)
                              console.log('drawerContent', item)
                            }} className="pricing-thumb">
                              <div className="d-flex">
                                <div>
                                  <p>{item.eventName}</p>
                                  <small>{evntType}</small>
                                </div>

                                <img src={poster} className="img-fluid rounded-circle pricing-tag ms-auto" alt="img" style={{ objectFit: 'cover' }} />

                              </div>

                              <p className="mt-3" style={{ minHeight: 80 }}>{item.description}</p>

                              <a className="link-fx-1 color-contrast-higher mt-4" href="#">
                                <span>Check Tickets</span>
                                <svg className="icon" viewBox="0 0 32 32" aria-hidden="true"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"><circle cx="16" cy="16" r="15.5"></circle><line x1="10" y1="18" x2="16" y2="12"></line><line x1="16" y1="12" x2="22" y2="18"></line></g></svg>
                              </a>
                              {/* <a className="link-fx-1 color-contrast-lower mt-4 ms-5" href="ticket.html">
                                <span>More Info</span>
                                <svg className="icon" viewBox="0 0 32 32" aria-hidden="true"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"><circle cx="16" cy="16" r="15.5"></circle><line x1="10" y1="18" x2="16" y2="12"></line><line x1="16" y1="12" x2="22" y2="18"></line></g></svg>
                              </a> */}
                            </div>
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <p className="text-danger mt-3 text-center">No events retrieved</p>
                  )}
                </>
              )}

            </div>
          </div>
        </section>

      </main>

      <Footer />
    </>
  )
}

export default PaymentConfirmation
