import React from 'react'
import { Helmet } from "react-helmet";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const TermsNConditions = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Conditions - Tickets2Go</title>
        <link rel="canonical" href="https://tickets2go.net/terms" />
        <meta property="og:title" content="Terms & Conditions Tickets2Go" />
        <meta property="og:description"
          content="We make buying tickets to your favourite events very easy, comfortable and accept almost all payment methods. In a few steps, you will be ready to go" />
        <meta property="og:image"
          content="https://api.tickets2go.net/avatars/tickets2Go.png" />
        <meta property="og:url" content="https://tickets2go.net/terms" />
        <meta name="keywords" content="concert tickets ticketstogo tickets2go tickets 2 go quicket eventbrite party events" />
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;700&display=swap" rel="stylesheet" />

      </Helmet>
      <main>
        <Navbar reloadNav={1} />
        <section className="ticket-section section-padding" id="section_5">
          <div className="section-overlay"></div>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 col-12 text-center ">
                <h2 className='text-secondary'>Terms & Conditions</h2>
              </div>
            </div>
          </div>
        </section>
        <section class="pricing-section pt-5 pb-5 section-bg" id="section_5">
          <div class="container">
            <div class="row">
              <p class="mb-3">
              Welcome to Tickets2Go, where we strive to provide comprehensive event management services tailored to your needs. Before utilizing our services, please carefully read and understand the following Terms and Conditions:
              </p>
              <h4 class="display-7 mb-2">ACCEPTANCE OF TERMS</h4>
              <p class="mb-3">
              By accessing or using Tickets2Go's services, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not use our services.
              </p>
              <h4 class="display-7 mb-2">ABILITY TO ACCEPT TERMS & CONDITIONS</h4>
              <p class="mb-3">
                Children under the age of 13 are not permitted to register with tickets2go.net. It is tickets2go.net's policy not to collect any information from anyone under the age of 13. You warrant that you are either more than 18 years or age or have consent of your parent or legal guardian such that you are legally able to agree to be bound by the Terms. In any event, You warrant that you are over the age of 13, as the Website is not intended for children under 13. If you are under 13 years of age, do not use or access the tickets2go.net website at any time or in any manner. If you are the parent or legal guardian of a child under 13, and that child has somehow registered with tickets2go.net, please send an e-mail to info@tickets2go.net for instructions on how to cancel your child’s registration. In the e-mail, please include your full name and address, your child's full name and address, your relationship to the child, your daytime and evening telephone numbers, your e-mail address and a signed statement that you are the child's parent or legal guardian. tickets2go.net reserves the right to seek additional information to verify your identity and status in relation to the child. tickets2go.net will use this information only to verify that you are the child's parent or legal guardian and for no other purposes.
              </p>
              <h4 class="display-7 mb-2">SERVICES OFFERED</h4>
              <p class="mb-3">
              <b>Online Ticket Sales :</b> We offer online ticket sales through our website, tickets2go.net. Ticket sales for free events are provided free of charge. For paid events, a service fee of 5% of the total collected amount will be charged.
              </p>
              <p class="mb-3">
              <b>Onsite Ticket Sales:</b> Our team facilitates onsite ticket sales during events, ensuring a smooth process for attendees.
              </p>
              <p class="mb-3">
              <b>Gate Access Management:</b> We manage gate access to ensure the security and smooth flow of attendees during events.
              </p>
              <p class="mb-3">
              <b>Event Ushers:</b> Our trained ushers assist in guiding attendees, providing information, and ensuring a pleasant experience throughout the event.
              </p>
              <p class="mb-3">
              <b>Ticket Wristbands:</b> We offer ticket wristbands for efficient entry management and identification purposes.
              </p>
              <p class="mb-3">
              <b>Graphic Design Services:</b> We provide graphic design services for event posters and videos, ensuring visually appealing promotional materials.
              </p>
              <p class="mb-3">
              <b>Photography:</b> Our team captures memorable moments during events through professional photography services.
              </p>
              <h4 class="display-7 mb-2">ONLINE TICKETS DISCLAIMER</h4>
              <p class="mb-3">
              Third party organisers use our platform to promote their events
              </p>
              <p class="mb-3">
              When purchasing tickets for an event, you are contracting with the organiser and not with Tickets2Go. We do not verify whether events are genuine, nor whether any statements or descriptions made by the organisers are accurate. Prices and refund policies are set by the organisers. The organisers are responsible for delivering the event.
              </p>
              <p class="mb-3">
              We are not responsible for any cancellation, postponement, limitation, or restriction of an event
              </p>
              <p class="mb-3">
              Any disputes or complaints you have about an event are between you and the organiser, unless the issue concerns our platform or processes. We may mediate the dispute or complaint in certain cases, but this is in our sole discretion.
              </p>
              <p class="mb-3">
              You must only list events that are genuine. You must not deceive (or attempt to deceive) consumers by displaying false, inaccurate or misleading information. Similarly, the event itself must meet all applicable laws, rules and regulations and must otherwise comply with these terms
              </p>
              <p class="mb-3">
              By posting a listing on the platform, you warrant that (i) you are duly authorized to promote the relevant event, (ii) you are not infringing any intellectual property, or other rights, and (iii) you are acting legally and in accordance with all applicable laws. You will pay us for any loss we suffer because of any of these warranties being incorrect
              </p>
              <p class="mb-3">
              We aren’t obliged to review listings, but we may. We can decline or remove a listing, in our discretion
              </p>
              <p class="mb-3">
              We act as your limited payment agent, to collect payments from consumers for your event. You authorise us to (i) collect, hold, disburse and retain proceeds on your behalf; (ii) where we determine a consumer is entitled to a refund, to issue the refund; and (iii) in the case of events, to enable consumers to transfer or upgrade event tickets (if you have enabled this function). In accepting the appointment as your limited agent, we assume no liability for any act or omission on your part
              </p>
              <h4 class="display-7 mb-2">PAYMENTS AND FEES</h4>
              <p class="mb-3">
              For paid events, a service fee of 5% of the total collected amount will be charged for ticket sales processed through Tickets2Go, free events are free of charge.
              </p>
              <p class="mb-3">
              If any charge-back claim occurs, it is your responsibility to provide the organiser with all necessary documentation to resolve the claim
              </p>
              <p class="mb-3">
              Our fees may change from time to time, and may vary based on several factors including but not limited to product type, price, country, payment method, and currency. All fees are subject to change at Tickets2Go's discretion. Any changes will be communicated in advance.
              </p>
              <p class="mb-3">
              If we suspect that a transaction may be fraudulent, we may request proof (for example, proof of identification, proof of address, etc.) to determine the validity of the transaction.
              </p>
              <p class="mb-3">
              Payments to organisers will take upto 3 business days and only after the event is done
              </p>
              <h4 class="display-7 mb-2">REFUNDS</h4>
              <p class="mb-3">
              All transactions are between you and the organiser. You must contact the organiser with any refund requests. Whether you are entitled to a refund will depend on the refund policy of the event. We do not determine (nor are we responsible for) the refund policy; this is determined by the event organiser.
              </p>
              <p class="mb-3">
              Any refunds will exclude our fee and you will always be responsible for any charges incurred in processing any refund
              </p>
              <p class="mb-3">
              Your refund policy as an organiser must be fair and reasonable, and you must comply with all applicable laws when it comes to refunds and cancellations. You must respect all consumer rights, particularly as they relate to refunds
              </p>
              <p class="mb-3">
              You grant us the right to (i) decide, in our reasonable discretion, if a consumer should be refunded, and (ii) where we believe a consumer should be refunded, to refund the consumer on your behalf, where we hold proceeds. You have no right to demand that we pay you any amount which we have refunded to a customer
              </p>
              <p class="mb-3">
              You must refund all consumers where an event is cancelled. If an event is cancelled, or where we believe there is a risk of cancellation or non-performance, we will initiate a refund to the relevant consumers of the transaction value (less our fee)
              </p>
              <p class="mb-3">
              If, for any reason, we have already paid the ticket proceeds to you (or the relevant beneficiary), you are responsible for processing consumer refunds
              </p>
              <p class="mb-3">
              You must reimburse us for any loss suffered or incurred by us due to a charge-back or refund claim being made against Tickets2Go in connection with your event. We are entitled to set-off the amount against any future payment due to you
              </p>
              <p class="mb-3">
              You will pay us for any losses (including reputational losses) which we suffer because of your failure to comply with any legal obligations to a consumer.
              </p>
              <h4 class="display-7 mb-2">ASSUMPTION OF RISK BY YOU</h4>
              <p class="mb-3">
                Tickets2Go sends tickets and transactional updates via email to the email address of the person making the order. Emails may be non-delivered due to a wide range of issues, from the user entering their email address incorrectly, upstream provider issues, or issues on the receiver's mailbox (such as a full inbox, company firewalls, or ending up in junk and promo folders). Tickets2Go assumes no responsibility for non-delivery of email and ensures that your tickets are stored and accessible in your Tickets2Go account for ease of access at any time.
              </p>
              <p class="mb-3">
                Tickets2Go does not guarantee that any event will meet expectations, or be safe, secure, uninterrupted, or error-free
              </p>
              <p class="mb-3">
                Tickets2Go has no control over and does not guarantee the quality, safety, accuracy or legality of any event or content associated with an event. Tickets2Go has no responsibility to you for, and we disclaim all liability from, the acts or omissions of any third parties.
              </p>
              <p class="mb-3">
                Tickets2Go does not guarantee that any event will meet expectations, or be safe, secure, uninterrupted, or error-free
              </p>
              <p class="mb-3">
              There are risks associated with attending physical events; by attending an event, you choose to assume those risks voluntarily. These risks include, but are not limited to, damage to property, personal injury, illness, disability or death
              </p>
              <p class="mb-3">
              Certain events put you at increased risk. For example, attending a rock music festival could result in you suffering from hearing loss, seizures, or other injuries and health conditions caused by loud sounds, large crowds and special effects (such as flashing lights, laser projections, fireworks, etc.).
              </p>
              <p class="mb-3">
              In attending an event, you assume all risks of loss associated with loss or damage to your person and/or property
              </p>
              <p class="mb-3">
              You will pay us for any loss we suffer because you failed to comply with your responsibilities to a consumer, or otherwise failed to adequately deal with a consumer complaint.
              </p>
              <p class="mb-3">
              For physical events, you are solely responsible for the health and safety of all employees, service providers, ticket holders, suppliers, performers, vendors and other attendees. You must not organise or host an event unless you have appropriate resources. If any injury, damage or loss is suffered by any person at your event, we may require you to issue a public statement confirming that Tickets2Go is not affiliated with your event.
              </p>
              <p class="mb-3">
              Tickets2Go will not be liable for any indirect, special or consequential losses of any kind whatsoever arising from, or in connection with your use of the platform or our services. “Force Majeure” means, in relation to Tickets2Go, any circumstances beyond the reasonable control of Tickets2Go including (without limitation) any acts of war or public enemy, civil commotion or strife, political or civil disturbance, riot, insurrection, strikes, lock-out, interruption of transport, labour dispute, boycott, fire, explosion, flood, storm, infectious or contagious disease, epidemic, pandemic or natural or physical disaster, sabotage, terrorism, acts or omissions of any government, government agency, provincial or local or similar authority, laws or regulations or any acts of God or forces of nature. Tickets2Go shall not be liable a result of any delays or failures of performance of its obligations if and to the extent that such delay or failure is caused by a Force Majeure event.
              </p>
              <p class="mb-3">
              Indemnification: You agree to indemnify and hold Tickets2Go, its affiliates, and their respective officers, directors, employees, and agents harmless from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorney's fees, arising out of or in any way connected with your use of Tickets2Go's services.
              </p>
              <p class="mb-3">
              Tickets2Go will not be liable for any indirect, special or consequential losses of any kind whatsoever arising from, or in connection with your use of the platform or our services. “Force Majeure” means, in relation to Tickets2Go, any circumstances beyond the reasonable control of Tickets2Go including (without limitation) any acts of war or public enemy, civil commotion or strife, political or civil disturbance, riot, insurrection, strikes, lock-out, interruption of transport, labour dispute, boycott, fire, explosion, flood, storm, infectious or contagious disease, epidemic, pandemic or natural or physical disaster, sabotage, terrorism, acts or omissions of any government, government agency, provincial or local or similar authority, laws or regulations or any acts of God or forces of nature. Tickets2Go shall not be liable a result of any delays or failures of performance of its obligations if and to the extent that such delay or failure is caused by a Force Majeure event.
              </p>
              <h4 class="display-7 mb-2">AMENDMENTS</h4>
              <p class="mb-3">
                Tickets2Go may amend or revise the Terms or any other policy of Tickets2Go at any time and you agree to be bound by such revised Terms or policy. Any such amendment or revisions will become effective upon the date it is first posted to this site. It is your responsibility to return to the Website from time to time to review the most current Terms and other Tickets2Go policies. Tickets2Go does not and will not assume any obligation to notify you of changes to the Terms or any other policy of Tickets2Go.
              </p>
              <h4 class="display-7 mb-2">PRIVACY POLICY</h4>
              <p class="mb-3">
               By using Tickets2Go's services, you agree to our Privacy Policy, which outlines how we collect, use, and disclose personal information. Please review our Privacy Policy available on our website.
              </p>
              <p class="mb-3">
                Updated wed, 17th April 2024
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default TermsNConditions
