import React from 'react'
import { Helmet } from "react-helmet";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy - Tickets2Go</title>
        <link rel="canonical" href="https://tickets2go.net/privacy" />
        <meta property="og:title" content="Privacy Policy Tickets2Go" />
        <meta property="og:description"
          content="We make buying tickets to your favourite events very easy, comfortable and accept almost all payment methods. In a few steps, you will be ready to go" />
        <meta property="og:image"
          content="https://api.tickets2go.net/avatars/tickets2Go.png" />
        <meta property="og:url" content="https://tickets2go.net/privacy" />
        <meta name="keywords" content="concert tickets tickets2go tickets 2 go quicket eventbrite party events" />
        <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;700&display=swap" rel="stylesheet" />

      </Helmet>
      <main>
        <Navbar reloadNav={1} />
        <section className="ticket-section section-padding" id="section_5">
          <div className="section-overlay"></div>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 col-12 text-center ">
                <h2 className='text-secondary'>Privacy Policy</h2>
              </div>
            </div>
          </div>
        </section>
        <section class="pricing-section pt-5 pb-5 section-bg" id="section_5">
          <div class="container">
            <div class="row">
              <h4 class="display-6 mb-2">Legal Notice</h4>
              <p class="mb-3">
                This website is operated by Tickets2Go, based in Kampala, Uganda.
              </p>
              <h4 class="display-7 mb-2">What information will Tickets2Go collect about me?</h4>
              <p class="mb-3">
                When you contact Tickets2Go to make a donation or sign up to any of Tickets2Go’s activities or online content, such as newsletters, or message boards, or you telephone, email, write to Tickets2Go, or engage with Tickets2Go via social media channels, we may receive and retain personal information about you. This can consist of information such as your name, email address, postal address, telephone or mobile number. Your payment informations will never been stored or known by Tickets2Go since we use Flutterwave as a highly secured payment platform. Tickets2Go also uses cookies. Cookies are small text files on your device. They are made by your web browser when you visit a website. Every time you go back to that website, your browser will send the cookie file back to the website’s server. They improve your experience of using a website, for example, by remembering your preference settings and by measuring your use of a website to ensure that it meets your needs. Tickets2Go hosted this website on a server in europe. European websites must follow the Commission’s guidelines on privacy and data protection and inform users that cookies are not being used to gather information unnecessarily.
              </p>
              <h4 class="display-7 mb-2">Will Tickets2Go share my personal information with anyone else?</h4>
              <p class="mb-3">
                Tickets2Go will not sell your information to third party organisations, and we do not share your personal information with third parties for their benefit. We will only use your information within Tickets2Go for the purposes for which it was obtained. Sometimes Tickets2Go contracts third parties like Ammado to process your information on our behalf. We require these third parties to comply strictly with our instructions and data protection laws. We will keep your information confidential except where disclosure is required or permitted by law (for example to government bodies for tax purposes or law enforcement agencies for the prevention and detection of crime, subject to such bodies providing us with a relevant request in writing).
              </p>
              <h4 class="display-7 mb-2">What type of cookies are used by Tickets2Go?</h4>
              <p class="mb-3">
                The types of cookies used on our site can generally be put into one of the following categories: strictly necessary; analytics; functionality; and social media. You can find out more about each of the cookie categories below. Strictly Necessary Cookies These cookies are essential to make our website work. They enable you to move around the site and use its features. Without these cookies, services that are necessary for you to be able to use our site such as accessing secure areas cannot be provided. Analytics Cookies These cookies collect information about how people are using our website, for example which pages are visited the most often, how people are moving from one link to another and if they get error messages from certain pages. These cookies don’t gather information that identifies you. All information these cookies collect is grouped together with information from other people’s use of our site on an anonymous basis. Overall, these cookies provide us with analytical information about how our site is performing and how we can improve it. Functionality Cookies These cookies allow us to remember choices you make and tailor our site to provide enhanced features and content to you. For example, these cookies can be used to remember your user name, language choice or country selection, they can also be used to remember changes you’ve made to text size, font and other parts of pages that you can customise. Social Media Cookies In order to enhance your internet experience and to make the sharing of content easier, some of the pages on our website may contain tools or applications that are linked to third party social media service providers such as Facebook, Twitter or Google+. Through these tools or applications the social media service provider may set its own cookies on your device. We do not control these cookies and you should check the social media service provider’s website for further details about how they use cookies.
              </p>
              <h4 class="display-7 mb-2">The use of social media buttons with "Shariff"</h4>
              <p class="mb-3">
                The use of social media buttons with "Shariff"On our website we promote a project by c't, called "Shariff". "Shariff" replaces the typical share-buttons of social networks and thereby protects surfing behavior "Shariff" integrates the share buttons of social networks on our website as a graphic containing a link to the appropriate social network. By clicking on the appropriate icon you will be redirected to the services of your network. The Shariff button provides direct contact between the social network and our users only when the visitor actively clicks the 'Share' button. Only then will your data be transferred to the respective social network. If, however, the Shariff button is not clicked, no exchange will be made between you and the social network. For more information about the c't project "Shariff" visit http://www.heise.de/ct/artikel/Shariff-Social-Media-Buttons-mit-Datenschutz-2467514.html
              </p>
              <h4 class="display-7 mb-2">Website disclaimer</h4>
              <p class="mb-3">
                The information contained in this website is for general information purposes only. The information is provided by Tickets2Go and while we endeavour to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website. Through this website you are able to link to other websites which are not under the control of Tickets2Go. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them. Every effort is made to keep the website up and running smoothly. However, Tickets2Go takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
              </p>
              <h4 class="display-7 mb-2">Information/Cancellation/Deletion</h4>
              <p class="mb-3">
                On the basis of the Federal Data Protection Act, you may contact us at no cost if you have questions relating to the collection, processing or use of your personal information, if you wish to request the correction, blocking or deletion of the same, or if you wish to cancel explicitly granted consent. Please note that you have the right to have incorrect data corrected or to have personal data deleted, where such claim is not barred by any legal obligation to retain this data. Sample Data Privacy Policy Statement provided by the Law Offices of Weiß & Partner
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default PrivacyPolicy
