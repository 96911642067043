import React from 'react'
import '../App.css'

const RightSideDrawer = ({ isOpen, onClose, handleBackdropClick, children }) => {
  return (
    <div style={{right: isOpen ? '0' : '-100%'}} className={`drawer ${isOpen ? 'open' : ''}`}>
      {isOpen && <div className="backdrop" onClick={handleBackdropClick}></div>}
      <div className="drawer-content">
        <button className="close-button" onClick={onClose}>
          <i className='fa fa-times fa-lg'></i>
        </button>
        {children}
      </div>
    </div>
  )
}

export default RightSideDrawer
